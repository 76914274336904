<template>
  <div class="app-container">
    <nav>
      <div class="tips">
          <el-input v-model="queryValue" placeholder="请输入文件名"></el-input>
          <el-button @click="initTable()" type="primary" class="btn" icon="el-icon-search"  v-auth='"customerInfo:lookup"'>查询</el-button>
      </div>
      <div class="operation">
        <el-button @click="upload" type="primary" class="btn" v-auth='"electronicContract:upload"'><i class="iconfont iconicon-04"/> 上传</el-button>
        <el-button @click="uploadPicture" type="primary" class="btn" v-auth='"companySeal:upload"'><i class="iconfont iconicon-04"/> 印章图片</el-button>
        <el-button @click="handleExport" type="primary" class="btn" v-auth='"electronicContract:batchDownload"'><i class="iconfont iconicon-03"/> 批量下载</el-button>
      </div>
    </nav>

    <div class="insurance_table" v-loading='isLoading'>
      <comps-table :tableData="tableData" :tableHeader="tableHeader" @multipleSelection="handleSelectionChange" :selectionType='false'
       :total='total' @current-events="savePage" @size-events='saveSize' class="comp_table" v-if="tableData.length">
      </comps-table>
      <nodata v-else message="暂无数据" class="no_data" />
    </div>
    <!-- 上传弹窗 -->
   <upload-dialog :visible='uploadVis' :cancelDialog='uploadCancel' :confirmDialog='uploadConfirm'/> 
   <upload-picture-dialog :visible='uploadPictureVis' :cancelDialog='uploadPictureCancel' :confirmDialog='uploadPictureConfirm'/> 
   <!-- 下载弹窗 -->
   <export-dialog :visible='exportVis' :cancelDialog='exportCancel' :confirmDialog='confirmExport'/>
   <!-- 预览弹窗 -->
   <preview-dialog :visible='previewVis' :item='previewItem' :cancelDialog='previewCancel' :confirmDialog='previewCancel'/>
   <!-- 删除弹窗 -->
    <del-dialog :visible="delDialog" :cancelDialog="cancelDialog" :confirmDialog="confirmDialog" :isdeling="isdeling" :config="config" >
      <div class="answer">确认删除吗？</div>
    </del-dialog>
  </div>
</template>

<script>
  import compsTable from "@/components/table/table";
  import delDialog from '@/components/Dialog'
  import uploadDialog from './dialog/upload'
  import uploadPictureDialog from './dialog/uploadPicture'
  import exportDialog from './dialog/export'
  import previewDialog from './dialog/preview'
  import axios from "axios";
  export default {
    components: { compsTable , uploadDialog, uploadPictureDialog , exportDialog , delDialog ,previewDialog},
    data() {
      return {
        isLoading: false,
        hasData: false,
        tableHeader: [
          { prop: "name", label: "文件名", center: 'left' ,
          render: (h, data) => {
              return (
                <span>
                  {data.row.name}
                </span>
              );
            },
          },
          {prop:'sealPath',label:'印章图片',center: 'center',
            render: (h, data) => {
              // {data.row.sealPath}
              if(data.row.sealPath){
                return (
                    <span v-if="{}" style='color:#409eff;cursor: pointer;' onClick={() => { this.preview(data.row);}}>
                  查看
                  </span>
                );
              }
              },
          },
          {prop:'uploadTime',label:'上传时间',center: 'center',},
          {prop:'contractStatus',label:'电子合同状态',center: 'center',
            render: (h, data) => {
                return (
                  <span >
                    {data.row.contractStatus == '00'?'未签名':'已签名'}
                  </span>
                );
              },
          },
          {
            label: "操作",
            center: 'center',
            width:'150',
            render: (h, data) => {
                return (
                  <span>
                    <el-button class='btn' type="text" v-auth="customerInfo:edit" onClick={() => { this.download(data.row);}}>
                      下载
                    </el-button>
                      <el-button class='btn' type="text" v-auth="electronicContract:delete" onClick={() => { this.delete(data.row);}}>
                        删除
                      </el-button>
                  </span>
                );
            },
          },
        ],
        queryValue:'',
        page: 1,
        tableData: [],
        total: 0,
        pageSize: 20,
        isdeling: false,
        multipleSelection:[],

        // 删除提示 弹窗配置
        config: {
          top: '20vh',
          width: '16%',
          title: '提示',
          btnTxt: ["确定", "取消"]
        },
        // 保存选中 id 
        id: '',

        // 弹窗状态
        delDialog: false,
        uploadVis: false, //上传电子合同
        uploadPictureVis: false, //上传印章
        exportVis:false,
        previewVis:false,
        previewItem:{},
      }
    },
    methods: {
      savePage(val){
        this.page = val
        this.initTable()
      },
      saveSize(val) {
        this.pageSize = val
        this.initTable()
      },
      //--------------导出------------------
      handleExport(){
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.exportVis = true;
      },
      exportCancel(){
        this.exportVis = false;
      },
      confirmExport(){
        axios({
          method: 'get',
          url: '/api/einvoice/contractSign/batchDownload',
          responseType: 'blob',
          params: {
            name: this.queryValue, 
            companyId: this.getToken('companyId'),
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let url = window.URL.createObjectURL(new Blob([data], { type: "application/zip" }))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url;
          var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
          link.setAttribute('download', '电子合同' + now + '.zip')
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.isdeling = false
          this.exportVis=false
        }).catch(err=>{
          this.isdeling = false
        })
        this.exportVis=false;
      },
      // 下载单个
      download(item){
        this.isDowning = true
          var type='application/pdf;chartset=UTF-8'
          axios({
            method: 'get',
            url: '/api/einvoice/contractSign/download',
            responseType: 'blob',
            params: {
              id:item.id,
              companyId: item.companyId,
            },
            headers: {
              'Content-Type': "application/json;charset=utf-8",
              'Authorization': this.getCookies('Authorization'),
              'group' :process.env.VUE_APP_GROUP
            }
          }).then((res) => {
            let data = res.data
            let url = window.URL.createObjectURL(new Blob([data], { type: type}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url;
            link.setAttribute('download', item.name)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.isDowning = false
          }).catch(err =>{
            this.isDowning = false;
          })
      },
      //---------上传----------
      // 上传印章图片
      uploadPicture(){
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.uploadPictureVis = true;
      },
      uploadPictureConfirm(data){
        this.$store.dispatch("UploadCompanySeal",data).then(res => { // 以文件传输格式 file: (binary) 进行上传
          console.log(data);
          if (res.success) {
            this.isdeling = false
            einvAlert.success("上传成功","上传成功");
          }else{
            this.isdeling = false
            einvAlert.error("提示",res.msg);
          }
          this.uploadPictureVis = false
          this.fileList = []
        })
      },
      uploadPictureCancel(){
        this.uploadPictureVis = false;
      },
      // 上传电子合同
      upload() {
        if(this.getToken('hasJoinCompany') == 'false'){
          einvAlert.warning('提示','当前公司尚未通过开票接入申请,无法使用该模块','确定','我知道了')
          return false;
        }
        this.uploadVis = true;
      },
      uploadConfirm(data){
        this.isdeling = true
         this.$store.dispatch("UploadContract",data).then(res => { // 以文件传输格式 file: (binary) 进行上传
          console.log(data);
          if (res.success) {
            this.isdeling = false
            this.initTable()
            einvAlert.success("上传成功","上传成功");
          }else{
            this.isdeling = false
            einvAlert.error("提示",res.msg);
          }
          this.uploadPictureVis = false
          this.fileList = []
        })
        this.uploadVis = false;
      },
      uploadCancel(){
        this.uploadVis = false;
      },
      // 选中
      handleSelectionChange(val) {
        this.multipleSelection = val;
        this.id = this.multipleSelection.map(item => {
          return item.id
        }).join(',')
      },
      //批量删除
      selectDel(){
        if (this.multipleSelection.length == 0) {
          einvAlert.error("提示",'请选中需要删除的数据！')
        } else{
          this.delDialog = true
          this.id = this.multipleSelection.map(item => {
            return item.id
          }).join(',')
        }
      },
      //单选删除
      delete(row) {
        this.delDialog = true
        this.id = row.id
      },
      cancelDialog() { this.delDialog = false },
      confirmDialog() {
            this.isdeling = true
            let data = { companyId: this.getToken('companyId'),id: this.id, };
            this.$store.dispatch("DelCustomer", data).then(res => {
              this.isLoading = false;
            if (res.success) {
                this.isLoading = false;
                this.isdeling = false;
                einvAlert.success("提示",'删除成功')
                this.delDialog = false
                this.initTable()
              } else {
                this.isLoading = false;
                this.isdeling = false;
                einvAlert.error("提示",res.msg)
              }
              }).catch(err => {
                this.isLoading = false;
            })
      },
      // 预览
      preview(item){
        this.previewItem = item;
        this.previewVis = true;
      },
      previewCancel(){
        this.previewVis = false;
      },
      
      // 电子合同列表
      initTable() {
          let data = {
            name: this.queryValue, 
            companyId: this.getToken('companyId'),
            page: this.page,
            rows: this.pageSize,
          };
          this.$store.dispatch("FindContract", data).then(res => {
           if (res.success) {
              this.isLoading = false;
              this.tableData = res.data;
              this.total = res.totalCount;
            } else {
              this.isLoading = false;
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
               this.isLoading = false;
          })
      },
    },
    mounted() {
      this.initTable()
    }
  }
</script>

<style lang="less" scoped>
  .app-container{
    height: 100% !important;
  }
  nav{
    .text{
      font-size: 14px;
      width: 110px;
    }
    .tips{
      display: flex;
      align-items: center;
    }
    .btn{
      margin-left: 10px;
    }
  }
  .btn{
    margin-right: 10px;
  }
  .insurance_table {
    height: calc(100% - 4.82rem);
    padding: 24px 20px 8px;
    background-color: #fff;
    .comp_table {
      // border: 1px solid #ebeef5;
    }
  }
  // .answer {
  //   text-align: center;
  //   padding: 24px 0 14px;
  //   font-size: 16px;
  // }
</style>
