<template>
    <Dialog ref="dialog" :config="config" :width='width' :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='submitGroupForm' 
    :isdeling='isdeling' append>
      <el-form ref="ruleForm" class="demo-ruleForm" label-position='top'>
        <el-form-item label="电子合同" prop="year" label-width="6rem" required>
        <el-upload class="upload-demo" ref="upload" accept=".pdf" action="#" 
          :on-change="handleChange" :before-upload='beforeUpload' 
          :on-preview="handlePreview" :on-remove="handleRemove" 
          drag :http-request="httpRequest" :show-file-list="false"
          :file-list="fileList" :auto-upload="false" >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
         <div class="fileList" v-if="fileList.length > 0 ">
            <div v-for="(item,index) in fileList" :key="index" class="item">
              <i class="iconfont iconicon-08" style="color: #3d9eff;" v-if="item.name.substring(item.name.lastIndexOf('.')+1) == 'pdf'"/>
              <span class="name">{{item.name}}</span>
              <span class="close" @click="handleRemove(index,item)"> X </span>
            </div>
        </div>
          <span class="errorTips" :class="{show: message != '' }">{{message}}</span>
        </el-form-item>
        <el-form-item prop="addSeal" > 
              <div class="label"> <span >是否需要加盖公司印章</span>
              </div>
              <el-radio-group class="radioGroup" v-model="ruleForm.addSeal">
                  <el-radio label="00" value="00">不需要</el-radio>
                  <el-radio label="01" value="01">需要</el-radio>
              </el-radio-group>
            </el-form-item>
      </el-form>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog/index";
export default {
  props: {
    type:{
      type: String,
    },
    rowData :{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      ruleForm: {
            addSeal:'00'
      },
      config: {
        top: '14vh',
        title: '上传',
        center: true,
        btnTxt: ['保存','取消'],
      },
      width: '19rem',
      fileList:[],
      message:'',
    };
  },
  methods: {
      httpRequest(param) {
        console.log(param.file);
      },
      handleChange(file, fileList){
        let testFile = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testFile === 'pdf'
        const isLt1M = file.size / 1024 / 1024 < 1;

        if (!extension) {
          this.message = '文件类型为pdf！'
        } else if (!isLt1M) {
          this.message = '文件大小不能超过 1MB!'
        } else {
          this.fileList.push(file);
          console.log(this.fileList,'this.fileList');
        }
      },
      handleRemove(key, fileList) {
        this.fileList.splice(key, 1);
      },
      beforeUpload(file, fileList) {
        console.log(file,'1231312');
      },
      handlePreview(file) {
        console.log(file);
      },
      submitGroupForm(){
        if (this.fileList && this.fileList.length != 0 ) {
          let data = new FormData();
          data.append('companyId', this.getToken('companyId'))
          data.append('addSeal', this.ruleForm.addSeal)
            for (let i = 0; i < this.fileList.length; i++) {
              console.log(this.fileList[i]);
              data.append("files", this.fileList[i].raw)
          }
          this.confirmDialog(data)
        } else {
          this.isdeling = false;
          this.fileList = [];
          this.status = false;
          this.message = '请选择上传文件';
          return;
        }
      },
  },
  watch:{
      visible(val){
        this.fileList = []
      },
      message: function (val) {
        if(val){
          setTimeout(()=>{
            this.message = '';
          },1500)
        }
      },
  }
};
</script>

<style lang="less" scoped>
  .groupForm{
    width: 100%;
    margin: 0 auto;
    .submitGroupBtn{
      margin: 20px auto 0;
      display: block;
    }
  }
  /deep/ .el-upload-dragger{
    width: 16.75rem !important;
  }
  /deep/ .el-form--label-top .el-form-item__label{
    padding: 0;
  }
  /deep/ .el-form-item {
    margin-bottom: 0.7rem;
  }
  .label{
    // margin-left: 48px;
    span{
      margin-left: 5px;
    }
  }
  .label:before {
    content: '*';
    color: #F56C6C;
    margin-left: 2px;
  }
  .fileList{
    .item{
      display: flex;
      align-items: center;
      padding-left: 2%;
    }
    .name{
      margin-left: 2%;
      margin-right: 2%;
      width: 84%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .close{
      cursor: pointer;
    }
  }
  .radioGroup{
  }
  .errorTips{
    display: block;
    font-size: 12px;
    color: #f56c6c;
    margin-bottom: -15px;
    margin-top: -5px;
  }
</style>
