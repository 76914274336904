<!--
 * @Author: zhangsheng zhangsheng@whty.com.cn
 * @Date: 2023-05-11 10:43:44
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @LastEditTime: 2023-05-12 09:35:07
 * @FilePath: \web\src\views\electronicContract\dialog\export.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <Dialog ref="dialog" :config="config" :width='width' :visible='visible' :cancelDialog="cancelDialogs" :confirmDialog='tipsConfirm' 
    :isdeling='isdeling' append>
      <div class="answer">确认下载全部电子合同吗？</div>
      <!-- <div class="export_tip">导出选项</div>
      <el-radio-group v-model="exportType" class="export_select">
        <el-radio label="2">导出全部电子合同</el-radio>
        <el-radio label="1">导出选中电子合同</el-radio>
      </el-radio-group> -->
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog/index";
  export default {
    props: {
      visible: {
        type: Boolean,
      },
      isdeling : {
        type: Boolean,
        default: false,
      },
      title : {
        type: String,
      },
      cancelDialog: {
        type: Function,
        default: () => { },
      },
      confirmDialog: {
        type: Function,
        default: () => { },
      },
    },
    components: {
      Dialog,
    },
    data() {
      return {
        config: {
          title: '提示',
          top: '25vh',
          center: true,
          btnTxt: ['确定','取消'],
        },
        width: '20rem',
        exportType: '2'
      };
    },
    methods: {
      cancelDialogs(){
        this.cancelDialog();
      },
      tipsConfirm(){
        this.confirmDialog(this.exportType);
      },
    },
    watch:{
    }
  };
</script>

<style lang="less" scoped>
  .export_tip {
    text-align: center;
    font-size: 18px;
    margin-bottom: 35px;
  }
  .export_select {
    width: 100%;
    /deep/ label {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      font-size: 16px !important;
    }
  }
</style>
