<!--
 * @Author: Zs
 * @Date: 2021-10-19 15:47:58
 * @LastEditTime: 2023-05-12 10:31:25
 * @LastEditors: zhangsheng zhangsheng@whty.com.cn
 * @Description: 发票预览 组件
 * @FilePath: \web\src\views\invoice\modules\dialog\showPdf.vue
-->
<template>
  <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='cancelDialog' :width="width">
    <img :src="fileUrl" class="priviewImg" ref="img" alt="">
  </Dialog>
</template>

<script>
  import Dialog from "@/components/Dialog";
  export default {
    props: {
      item :{
        type: Object,
      },
      visible: {
        type: Boolean,
      },
      cancelDialog:{
        type: Function,
        default: () => {},
      },
      confirmDialog:{
        type: Function,
        default: () => {},
      }
  },
    components: {
      Dialog
    },
    data() {
      return {
        fileUrl: '',
        isLoading: false,
        config: {
          top: '10vh',
          title: '预览',
          center: true,
          btnTxt: ['确定','取消'],
        },
        width: '24rem',
      };
    },
    mounted(){
      // this.getFileUrl()
    },
    methods: {
      //图片预览
      getFileUrl (item){
        this.isLoading = true;
        let data ={
            companyId : item.companyId,
            path : item.sealPath,
          }
        /* 获取base64的地址 */
        this.$store.dispatch("ViewPicture", data).then(res => {
           if (res.success) {
              const dataInfo = res.data;
              this.fileUrl = 'data:image/jpeg;base64,' + dataInfo;
            this.isLoading = false;//得到返回的图片地址时，显示图片
            } else {
              einvAlert.error("预览失败",res.msg||res.message)
              this.isLoading = false; // 没得到正确的图片地址，直接关闭弹窗
            }
            }).catch(err => {
              console.log(err)
              this.isLoading = false;
          })
      },
    },
    watch:{
      visible(val){
        if(val){
          this.getFileUrl(this.item)
        }else{
          this.fileUrl = ''
        }
      },
    }
  };
</script>

<style lang="less" scoped>
  .priviewImg{
    width: 80%;
    margin: 0 auto;
    display: block;
    padding: 20px 0;
  }
</style>
