<template>
    <Dialog ref="dialog" :config="config" :width='width' :visible='visible'   :cancelDialog="cancelDialog" :confirmDialog='submitGroupForm' :isdeling='isdeling'  append>
        <div>
          <el-form ref="ruleForm" label-width="5rem" class="demo-ruleForm">
           <el-form-item label="印章图片" prop="year" required>
            <!-- <el-upload class="upload-demo" ref="upload" accept=".png,.jpg,.webp,.jpeg" action="#" 
              :on-change="handleChange" :before-upload='beforeUpload' 
              :on-preview="handlePreview" :on-remove="handleRemove" 
              drag :http-request="httpRequest"
              :file-list="fileList" :auto-upload="false" >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em>
                <p>仅支持上传一张印章图片</p>
              </div>
            </el-upload> -->

            <el-upload
                ref="upload"
                :file-list="fileList"
                class="avatar-uploader"
                accept=".png,.jpg,.jpeg,.webp" 
                action="#" 
                :limit='1'
                :before-upload='beforeUpload' 
                :on-change="handleChange" 
                :auto-upload="false"
                list-type="picture-card"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
              >
                <i v-if="fileList.length != 1 " class="el-icon-upload"></i>
              </el-upload>
                <div class="el-upload__text">
                  仅支持上传一张印章图片
                </div>
             <span class="errorTips" :class="{show: message != '' }">{{message}}</span>
           </el-form-item>
          </el-form>

          <div class="footer">
            <el-button @click="submitGroupForm" type="primary" :loading="isdeling" :disabled='status'>确定</el-button>
            <el-button @click="cancelDialog" type="primary" plain :loading="isdeling">取消</el-button>
          </div>

          <Dialog ref="dialog" :config="dialogConfig" :width='dialogWidth' :visible='dialogVisible' :cancelDialog="dialogCancel" :confirmDialog='dialogCancel' append>
            <img width="100%" style="margin-bottom: 20px;" :src="dialogImageUrl" alt="">
          </Dialog>
        </div>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog/index";
export default {
  props: {
    type:{
      type: String,
    },
    rowData :{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
    Dialog,
  },
  data() {
    return {
      config: {
        top: '14vh',
        title: '上传印章图片',
        center: true,
        btnTxt: [],
      },
      width: '23rem',
      ruleForm: {
        year:'',
        name: '',
        phone:'',
      },
      fileList:[],
      fileData:'',
      message:'',

      // 预览
      dialogConfig:{
         top: '8vh',
        title: '预览',
        center: true,
        btnTxt: [],
      },
      dialogWidth: '40rem',
      dialogVisible:false,
      dialogImageUrl:'',

      status : false,
    };
  },
  methods: {
      httpRequest(param) {
        console.log(param.file);
      },
      handleChange(file, fileList){
        this.fileList = fileList.slice(-1);  //限定上传文件为一个
      },
      handleRemove(file, fileList) {
        this.status = false;
        this.fileList = [];
        this.message = '请选择上传文件';
      },
      beforeUpload(file, fileList) {
        let testFile = file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testFile === 'png'
        const extension2 = testFile === 'jpg'
        const extension3 = testFile === 'webp'
        const isLt1M = file.size / 1024 / 1024 < 1;

        if(!extension && !extension2 && !extension3) {
          this.message = '上传文件只能是png或jpg、webp格式！'
        }else if (!isLt1M) {
          this.message = '上传Excel文件大小不能超过 1MB!'
        }else{
          this.fileData = file;
        }
      },
      handlePreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      submitGroupForm(){
        this.$refs.upload.submit();
        if (this.fileData == '') {
          this.fileList = [];
          this.message = '请选择上传文件';
          return;
        }
        let data = new FormData();
        data.append('companyId', this.getToken('companyId'))
        data.append('file', this.fileData)
        this.confirmDialog(data) 
      },
      async getCompanySeal(){
        this.isLoading = true
        this.fileList = [];
        let data = { companyId: this.getToken('companyId') };
        this.$store.dispatch("GetCompanySeal", data).then(res => {
        if (res.success) {
          if(res.data){
              this.status = true;
              this.fileList = [{status: 'success',url: 'data:image/jpeg;base64,'+ res.data}];
              this.isLoading = false;
          }else{
              this.status = false;
              this.fileList = [];
              this.isLoading = false;
          }
          } else {
            this.isLoading = false;
            einvAlert.error("提示",res.msg)
          }
          }).catch(err => {
            this.isLoading = false;
        })
      },
      dialogCancel(){
        this.dialogVisible=false;
      },
  },
  watch:{
      visible(val){
        if(val){
          this.getCompanySeal();
        }
      },
      message: function (val) {
        if(val){
          setTimeout(()=>{
            this.message = '';
          },1500)
        }
      },
  }
};
</script>

<style lang="less" scoped>
  .groupForm{
    width: 100%;
    margin: 0 auto;
    .submitGroupBtn{
      margin: 20px auto 0;
      display: block;
    }
  }
  /deep/ .el-form--label-top .el-form-item__label{
    padding: 0;
  }
  /deep/ .el-form-item {
    margin-bottom: 0.7rem;
  }
  .errorTips{
    display: block;
    font-size: 12px;
    color: #f56c6c;
    margin-bottom: -15px;
    margin-top: -5px;
  }
  .el-upload__text{
      margin-top: 5px;
      color: #888;
      font-size: 13px;
      line-height: 30px;
  }

    .avatar-uploader{
     height: 150px; 
    }
    .avatar-uploader .el-icon-upload:hover {
      border-color: #4F71FF;
    }

    .el-icon-upload {
        width: 150px!important;
        height: 150px!important;
        display: block;
        padding: 60px;
        margin-top: 0;
        margin-left: 0;
        border: 1px dashed #dcdfe6 !important;
    }
    /deep/ .el-upload--picture-card{
      border: none !important;
      background: transparent;
      height: 0;
      width: 0;
      --el-upload-picture-card-size : 0rem !important;
    }

    .footer {
      text-align: center;
      padding: 20px;
      .el-button {
        padding: 8px 20px;
        min-width: 80px;
        & + .el-button {
          margin-left: 40px;
        }
      }
    }
</style>
